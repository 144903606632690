import {HttpClient} from '@angular/common/http';
import {BackRouter} from '../../shared/services/back-router';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../../../environments/environment';
import {Promotion} from './entities/promotion.entity';
import {PromotionCurrency} from './entities/promotion-currency.entity';
import {UserDetails} from '../../shared/entity/user-details';
import {PromotionList} from './entities/promotion-list.entity';
import {UtilService} from '../../../services/util.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private httpClient: HttpClient,
    private br: BackRouter,
    private logger: NGXLogger,
    private utilService: UtilService,
  ) {
  }

  getCreditCard() {
    return this.httpClient.get(this.br.makeBackUrl('/rest/customer/cc'), {headers: { ignoreLoadingBar: '' }});
  }

  updateCreditCard(data) {
    return this.httpClient.post(
        this.br.makeBackUrl(`/rest/customer/cc`),
        data,
        {headers: { ignoreLoadingBar: '' }})
        .pipe(map((obj: { url: string }) => {
      return obj;
    }));
  }

  getFounds(): Observable<PromotionList> {
    const self = this;
      return this.httpClient.get(
          this.br.makeBackUrl('/rest/customer/cc/funds'),
          {headers: { ignoreLoadingBar: '' }})
          .pipe(map((obj: PromotionList) => {
            // Check currencies are the same
            for (const found of obj.promotionList) {
              if (!self.validatePromotionsCurrency(found.promotions)) {
                found.promotions = null;
              }
            }
            return obj;
      }));
  }

  getFoundsToPromise() {
      return this.httpClient.get(this.br.makeBackUrl('/rest/customer/cc/funds'), {headers: { ignoreLoadingBar: '' }}).toPromise();
  }

  getPromotionsRegistrationCC(forced = false) {
    const self = this;
    if (forced) {
      return this.getCreditCardPromotions();
    } else {
      const promotionsRegistrationCC = self.utilService.localStorageGetItem('promotionsRegistrationCC');
      if (promotionsRegistrationCC) {
        // Validate the promotions
        let promotions = JSON.parse(promotionsRegistrationCC);
        // If promotions not valid, I must return null
        if (!self.validatePromotionsCurrency(promotions)) {
          promotions = null;
          self.utilService.localStorageSetItem('promotionsRegistrationCC', '');
        }
        const simpleObservable = new Observable((observer) => {
          // observable execution
          observer.next(promotions);
          observer.complete();
        });
        return simpleObservable;
      } else {
        return self.getPaypalPromotions();
      }
    }
  }

  getCreditCardPromotions() {
    const self = this;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.br.makeBackUrl('/rest/promotion/registration/cc'), {headers: { ignoreLoadingBar: '' }}).pipe(map((obj: Promotion []) => {
      if (self.validatePromotionsCurrency(obj)) {
        self.utilService.localStorageSetItem('promotionsRegistrationCC', JSON.stringify(obj));
        return obj;
      } else {
        self.utilService.localStorageSetItem('promotionsRegistrationCC', '');
        return null;
      }
    }));
  }

  getPromotionsRegistrationPaypal(forced = false) {
    const self = this;
    if (forced) {
      // tslint:disable-next-line:max-line-length
      return self.getPaypalPromotions();
    } else {
      const promotionsRegistrationPaypal = self.utilService.localStorageGetItem('promotionsRegistrationPaypal');
      if (promotionsRegistrationPaypal) {
        // Validate the promotions
        let promotions = JSON.parse(promotionsRegistrationPaypal);
        // If promotions not valid, I must return null
        if (!self.validatePromotionsCurrency(promotions)) {
          promotions = null;
          self.utilService.localStorageSetItem('promotionsRegistrationPaypal', '');
        }
        const simpleObservable = new Observable((observer) => {
          // observable execution
          observer.next(promotions);
          observer.complete();
        });
        return simpleObservable;
      } else {
        return self.getPaypalPromotions();
      }
    }

  }

  private getPaypalPromotions() {
    const self = this;
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(this.br.makeBackUrl('/rest/promotion/registration/paypal'), {headers: { ignoreLoadingBar: '' }}).pipe(map((obj: Promotion []) => {
      if (self.validatePromotionsCurrency(obj)) {
        self.utilService.localStorageSetItem('promotionsRegistrationPaypal', JSON.stringify(obj));
        return obj;
      } else {
        self.utilService.localStorageSetItem('promotionsRegistrationPaypal', '');
        return null;
      }
    }));
  }

  private validatePromotionsCurrency(promotions: Promotion[]): boolean {
    const self = this;
    // Compare if all promotions have the same currency
    let auxCurrency: PromotionCurrency = null;
    for (const promotion of promotions) {
      if (!auxCurrency) {
        auxCurrency = promotion.info.currency;
      } else {
        if (auxCurrency.id !== promotion.info.currency.id) {
          return false;
        }
      }
    }
    // Compare currency from promotions with user currency
    const obj: UserDetails  = JSON.parse(self.utilService.localStorageGetItem('userDetails'));
    const userCurrency = obj.currency;
    if (userCurrency.id !== auxCurrency.id) {
      return false;
    }
    return true;
  }

  createCard(data: {
    payMethod: string,
    token: string,
    email: string,
    userId: number
  }) {
    return this.httpClient.post(this.br.makeBackUrl('/rest/payments/createCard'), data, {headers: { ignoreLoadingBar: '' }});
  }

  executePayment(data: {
    userId: number,
    amount: number,
    currency: string,
    description: string,
    payMethod: string,
    promotionId: number
  }) {
    return this.httpClient.post(this.br.makeBackUrl('/rest/payments/executePayment'), data, {headers: { ignoreLoadingBar: '' }});
  }

  addPayment(promotionId, data) {
    return this.httpClient.post(
        this.br.makeBackUrl(`/rest/customer/promo/${promotionId}/topup`),
        data,
        {headers: { ignoreLoadingBar: '' }});
  }



  payPalCreateOrder(id): Observable<string> {
      return this.httpClient.get(this.br.makeBackUrl(`/rest/payment/paypal/v2/create/${id}`), {headers: { ignoreLoadingBar: '' }})
      .pipe(map((order: { paymentID: string }) => {
        return order.paymentID;
      }));
  }
  payPalCreateOrderToPromise(id): Promise<String> {
      return this.httpClient.get(this.br.makeBackUrl(`/rest/payment/paypal/v2/create/${id}`), {headers: { ignoreLoadingBar: '' }})
        .pipe(map((order: { paymentID: string }) => {
          return order.paymentID;
        })).toPromise();
  }

  payPalAuth(data) {
      return this.httpClient.post(this.br.makeBackUrl('/rest/payment/paypal/v2/execute?orderID=' +
          data.orderID + '&payerID=' + data.payerID + '&facilitatorAccessToken=' + data.facilitatorAccessToken + '&error=' + data.error),
          {}, {headers: { ignoreLoadingBar: '' }});
  }

  getAutoRefill() {
      return this.httpClient.get(this.br.makeBackUrl(`/rest/customer/autorefill`), {headers: { ignoreLoadingBar: '' }});


  }
  getAutoRefillToPromise() {
    return this.httpClient.get(this.br.makeBackUrl(`/rest/customer/autorefill`), {headers: { ignoreLoadingBar: '' }}).toPromise();

  }
  setAutoRefill(data: {auto: boolean, timeInterval: number}) {
    return this.httpClient.put(this.br.makeBackUrl(`/rest/customer/autorefill`), data, {headers: { ignoreLoadingBar: '' }});
  }

  getPaypalConfig(createOrderOnServer, authorizeOnServer, onCancel, onError, onClick, onInit): any {
    const self = this;
    let currency = null;
    const aux = JSON.parse(self.utilService.localStorageGetItem('currency'));
    if (aux === null) {
      currency = {
        asciiSymbol: '$',
        id: 1,
        sign: 'USD'
      };
    } else {
      currency = aux;
    }
    return {
      currency: currency.sign,
      clientId: environment.paypal.clientId,
      createOrderOnServer: async (data) => {
        return createOrderOnServer(data);
      },
      authorizeOnServer: async (approveData) => {
        return authorizeOnServer(approveData);
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [
          {name: 'disable-funding', value: 'card'}
        ]
      },
      style: {
        label: 'paypal'
      },
      onCancel: (data, actions) => {
        onCancel(data, actions);

      },
      onError: err => {
        this.logger.debug('onError', err);
        onError(err);
      },
      onClick: (data, actions) => {
        this.logger.debug('onClick', data, actions);
        onClick(data, actions);
      },
      // onInit is called when the button first renders
      onInit: function(data, actions) {
        onInit(data, actions);
      }
    };

  }

  getPaypalConfiguration() {
    let currency = null;
    const aux = JSON.parse(this.utilService.localStorageGetItem('currency'));
    if (aux === null) {
      currency = {
        asciiSymbol: '$',
        id: 1,
        sign: 'USD'
      };
    } else {
      currency = aux;
    }
    return {
      'client-id': environment.paypal.clientId,
      'currency': currency.sign,
      'commit': true,
      'disable-funding': 'card',
    };
  }

  paypalButton(createOrder, onApprove, onCancel, onError, onClick, onInit) {
    const conf = {
      style: {
        label: 'paypal'
      },
      createOrder: () => {
        return createOrder;
      },
      onApprove: (approveData, actions) => {
        return onApprove(approveData);
      },
      onCancel: (data, actions) => {
        this.logger.debug('OnCancel', data, actions);
        onCancel(data, actions);

      },
      onClick: function () {
        onClick();
      },
      onError: function (err)  {
        this.logger.debug('onError', err);
        onError(err);
      },
      // onInit is called when the button first renders
      onInit: function(data, actions) {
        onInit(data, actions);
      }
    };
        return conf;
  }

  getLastPaymentSystemUsed() {
    const self = this;
    return this.httpClient.get(this.br.makeBackUrl('/rest/customer/transaction/last/provider'),
        {headers: { ignoreLoadingBar: '' }})
        .pipe(map((obj: any) => {
      let lastPaymentSystem = '0';
      switch (obj.result) {
        case 'PAYPAL':
          lastPaymentSystem = '1';
          break;
      }
      self.utilService.localStorageSetItem('lastPaymentSystem', lastPaymentSystem);
      return obj;
    }));
  }
}
