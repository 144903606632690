import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterModule, RouterStateSnapshot} from '@angular/router';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Category} from '../entity/category';
import {Reader} from '../modules/shared/entity/reader';
import {ReaderService} from '../modules/shared/services/reader-service';
import {AllEmiterService} from '../services/all-emiter.service';
// import {isScullyRunning} from '@scullyio/ng-lib';
import {UserService} from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class ReadersCategoryResolverService implements Resolve<Observable<ReadersCategoryResolverData>> {
    public data: ReadersCategoryResolverData;
    config = { offset: 0,  max: 10, moveBusyReaders: false , categoryId: 1};
    // config2 = { offset: 30,  max: 30, moveBusyReaders: false , categoryId: 1};
    // config3 = { offset: 60,  max: 30, moveBusyReaders: false , categoryId: 1};

    url: string;

    constructor(
        private userService: UserService,
        private readerService: ReaderService,
        private route: ActivatedRoute,
        private router: RouterModule,
        private allEmiterService: AllEmiterService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        this.allEmiterService.onWaitChange(true);
        const self = this;
        self.data = new ReadersCategoryResolverData();
        self.url = state.url;
        return this.readerService.getCategoryList()
            .pipe(
                concatMap((categories) => {
                    self.data.categories = categories;
                    // this.config.categoryId = self.findIdCategory(categories);
                    // this.config2.categoryId = self.findIdCategory(categories);
                    // this.config3.categoryId = self.findIdCategory(categories);
                    if (!this.config.categoryId) {
                        // delete this.config.categoryId;
                        // delete this.config2.categoryId;
                        // delete this.config3.categoryId;
                    }

                    const values = forkJoin([
                            // this.readerService.getReadersPopular(self.config).pipe(
                            //     catchError(err => of({})),
                            // ),
                            // this.readerService.getReadersPopular(self.config2).pipe(
                            //     catchError(err => of({})),
                            // ),
                            // this.readerService.getReadersPopular(self.config3).pipe(
                            //     catchError(err => of({})),
                            // ),
                            this.readerService.getPromotionCalls().pipe(
                                catchError(err => of({})),
                            ),
                            this.readerService.getPromotionRegistration().pipe(
                                catchError(err => of({})),
                            ),
                            this.userService.getPromotionAds().pipe(
                                catchError(err => of({})),
                            )
                        ]
                    ).pipe(
                        // map(([first, second, thrird, fourth, fifth, sixth]) => {
                        map(([  fourth, fifth, sixth]) => {
                            // @ts-ignore
                            // self.data.readers = first.entities;
                            // @ts-ignore
                            // self.data.readers = self.data.readers.concat(second.entities);
                            // @ts-ignore
                            // self.data.readers = self.data.readers.concat(thrird.entities);
                            // @ts-ignore
                            // self.data.quantityReaders = first.count;
                            self.data.promotionCalls = fourth;
                            self.data.promotionRegistrations = fifth;
                            self.data.promotionAds = sixth;

                            this.allEmiterService.onWaitChange(false);
                            return self.data;
                        })
                    );
                    return values;
                })
            );

    }

    findIdCategory(categories) {
        const path: string[] = this.getUrlPath();
        const foundParent = categories.find((item) => {
            return item.link === path[0];
        });
        if (foundParent) {
            const foundChild = foundParent.subCategories.find((item) => {
                return item.link === path.join('/');
            });
            return (foundChild) ? foundChild.id : foundParent.id;
        }
        return null;
    }

    getUrlPath() {
        return this.url.split('/').filter(item => item);
    }
}

export class ReadersCategoryResolverData {
    public categories: Category[];
    public readers: Reader[];
    public quantityReaders: number;
    public promotionCalls;
    public promotionRegistrations;
    public promotionAds;

    constructor() {
    }
}
