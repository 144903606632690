import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ReaderService} from '../../../services/reader-service';
import {Router} from '@angular/router';
import {AllEmiterService} from '../../../../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {UserService} from '../../../../../services/user.service';

@Component({
    selector: 'app-congratualations-modal',
    templateUrl: './congratualations-modal.component.html',
    styleUrls: ['./congratualations-modal.component.scss']
})
export class CongratualationsModalComponent implements OnInit, OnDestroy {
    id;
    reader;
    private subs = new SubSink();
    userIsLoggedIn = false;
    constructor(
        public bsModalRef: BsModalRef,
        public readerService: ReaderService,
        private router: Router,
        private allEmiterService: AllEmiterService,
        private userService: UserService
    ) {
        this.userIsLoggedIn = this.userService.isLoggedIn();
        this.id = this.readerService.loadSelectedReaderFromLocalStorage();
        // this.id = 54827;
        const self = this;
        if (this.allEmiterService.subsHideDialog === undefined) {
            this.subs.sink = this.allEmiterService.subsHideDialog = this.allEmiterService.invokeHideDialog.subscribe(() => {
                self.bsModalRef.hide();
                // Open tab
            });
        }
    }

    ngOnInit() {
        if (this.id) {
            this.subs.sink = this.readerService.getReaderById(this.id).subscribe((reader) => {
                this.reader = reader;
                this.readerService.removeSelectedReaderFromStorage();
            });
        }
    }

    goToPage(link) {
        this.bsModalRef.hide();
        this.router.navigate([link]);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
