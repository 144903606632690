import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
    name: 'dev',
    production: true,
    backend_url: 'https://www.dev.lifereader.com',
    api_prefix: '/dispatcher',
    electronPath: '.',
    proxy_campaign_monitor: 'https://dev.lifereader.com',
    paypal: {
        clientId: 'AUMEl36UBEmR1KyFAeNu28xJ5YmVkNOpu_BktJu0G_DK7vOzNYsYZu7Elsm-B_c4XoRwC43pwbAdOMnB'
    },
    siteName: 'LifeReader',
    reloadReaders: 300000,
    intervalPing: 30000,
    intervalReconnection: 5000,
    electron: false,
    oldChat: true,
    chatUrlOld: '/ChatClientWindow?ngsw-bypass=true',
    google: {
        analytics: {
            key: 'UA-52745302-1'
        },
        tagManager: {
            key: 'GTM-5LDWFR'
        }
    },
    nodeJsServer: 'https://www.dev.lifereader.com/newchat/',
    showNextReaderWhenReadingFails: false,
    recaptcha: {
        siteKey: '6LcXz7wZAAAAABcUOH7tJrOV-wx35gpN8nJ8HBJY'
    },
    appVersion: require('../../package.json').version,
    options: {
        call: {
            twilio: true,
            asterisk: false
        }
    },
    socketIo: {
        url: 'https://socketio.dev.lifereader.com',
        path: ''
    },
    nestJs: {
        url: 'https://api.dev.lifereader.com'
    },
    logger: {
        level: NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.ERROR,
        disableConsoleLogging: false
    },
    test: {
        delayRedirectChat: 0,
        randomData: true,
    }

};
