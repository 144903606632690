import {Inject, Injectable, Injector, PLATFORM_ID} from '@angular/core';
import {SubSink} from 'subsink';
import {UserService} from './user.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {WINDOW} from '../providers/window.provider';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {UtilService} from './util.service';
import {Router} from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {isPlatformBrowser} from '@angular/common';
import {AllEmiterService} from './all-emiter.service';

declare let ga: Function;
@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsEventsService {
    private subs = new SubSink();
    private gtmService: GoogleTagManagerService;
    private gaService: GoogleAnalyticsService;
    private cookieInterval = null;
    private isBrowser: boolean = isPlatformBrowser(this.platformId);
    constructor(
        private userService: UserService,
        private injector: Injector,
        private cookie: CookieService,
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window,
        public router: Router,
        private allEmiterService: AllEmiterService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        const self = this;
        const userAgent = this.window.navigator.userAgent;
        if (!userAgent.includes('Lighthouse') && this.isBrowser) {
            this.gtmService = this.injector.get<any>(GoogleTagManagerService);
            this.gtmService.addGtmToDom().then(async function () {
                await self.sentGaClientId();
            });

            this.subs.sink = this.allEmiterService.subsLoggedIn = this.allEmiterService.invokeLoggedIn.subscribe(async (data) => {
                await self.sentGaClientId();
            });
        }
    }

    async sentGaClientId() {
        const self = this;
        try {
            // @ts-ignore
            const gaClientId = ga.getAll()[0].get('clientId');
            await self.userService.gaClientId(gaClientId).toPromise();
        } catch (e) {
        }
    }

    public async pageView(url) {
        const self = this;
        try {
            const gtmTag = {
                event: 'page',
                pageName: url
            };

            await this.gtmService.pushTag(gtmTag);
        } catch (e) {
            self.utilService.setTimeout(async function () { await self.pageView(url); }, 1000);
        }
    }

    public async creditCardUpdated() {
       await this.completeRegistration();
    }


    public async userFinishFirstStep(userId: number) {
        await this.gtmService.pushTag({
            'event': 'GAEvent',
            'eventCategory': 'Registration',
            'eventAction': 'FirstLogin',
            'eventLabel': `${userId}`,
            'eventPlatform': 'Angular'
        });
    }

    public async eCommerceEvent(transactionId, host, revenue, currency, product, productCode, referralKey, dim1, dim2) {
        const e = {
            'event': 'eCommerce',
            'ecom.tr_id': transactionId,
            'ecom.tr_affl': location.host,
            'ecom.tr_rev': revenue,
            'ecom.tr_cur': currency,
            'ecom.tr_name': product,
            'ecom.tr_sku': productCode,
            'ecom.tr_referral_key': referralKey,
            'ecom.tr_category': 'reading'
        };

        if (dim1 && dim2) {
            e['ecom.dim1'] = dim1;
            e['ecom.dim2'] = dim2;
        }

        await this.gtmService.pushTag(e);

        let  transactionPage = '';
        if (product === 'chat-reading') {
            transactionPage = '/dvp/chat/transaction_COMPLETE';
        } else if ( product === 'call-reading') {
            transactionPage = '/dvp/phone/transaction_COMPLETE';
        }
        const transaction = {
            'event': 'transaction',
            'transactionId': transactionId,
            'transactionTotal': revenue,
            'transactionProducts': [{
                'sku': productCode,
                'name': product,
                'price': revenue,
                'quantity': 1,
                'category': 'reading'
            }],
            'dimension1': dim1,
            'dimension2': dim2,
            'currency': currency,
            'transactionPage': transactionPage
        };
        await this.gtmService.pushTag(transaction);

        // This is for GA4
        // console.og('Sending goal 8 or 9');
        const purchase = {
            'event': 'reading',
            'transaction_id': transactionId,
            'value': revenue,
            'dimension1': dim1,
            'dimension2': dim2,
            'currency': currency,
            'reading': product
        };
        await this.gtmService.pushTag(purchase);

        // send info for ecommerce for ga4
        await this.purchase(
            transactionId,
            revenue,
            productCode,
            product,
            currency);

    }

    public async completeRegistration() {
        await this.gtmService.pushTag({
            'event': 'GAEvent',
            'eventCategory': 'Registration',
            'eventAction': 'complete',
            'eventPlatform': 'Angular'
        });
    }

    /**
     * This is use on GTM to send data to Facebook
     * @param revenue
     * @param currency
     */
    public async bonusUsed(revenue, currency) {
        const gtmTag = {
            'event': 'Bonus',
            'bonus.rev': revenue,
            'bonus.cur': currency
        };
        // this.gtmService.pushTag(gtmTag);
        await this.gtmService.pushTag(gtmTag);
    }

    /**
     * This is use on GTM to send data to GA for goal 7
     * @param referealKey
     * @param userId
     */
    public async fireCCRegistrationEvent(referealKey, userId) {
        const gtmTag = {
            'event': 'GAEvent',
            'eventCategory': 'Registration',
            'eventAction': 'Complete',
            'eventReferral': `${referealKey}`,
            'eventLabel': `${userId}`,
            'eventPlatform': 'Angular',
            'eventMethod': 'CC'
        };
        await this.gtmService.pushTag(gtmTag);
    }

    /**
     * LEGACY DOES NTO WORK IN GA4
     * This is use on GTM to send data to GA for goal 7
     * @param referealKey
     * @param userId
     */
    public async fireFirstPaypalEvent(referealKey, userId) {
        const gtmTag = {
            'event': 'GAEvent',
            'eventCategory': 'Registration',
            'eventAction': 'Complete',
            'eventReferral': `${referealKey}`,
            'eventLabel': `${userId}`,
            'eventPlatform': 'Angular',
            'eventMethod': 'Paypal'
        };
        await this.gtmService.pushTag(gtmTag);
    }


    public async viewFeedback(consultantId: number) {
        const self = this;
        try {
            await this.googleAnalyticsTag('ViewFeedback', 'feedback', `${consultantId}`);
        } catch (e) {
            self.utilService.setTimeout(function () {
                self.viewFeedback(consultantId);
            }, 1000);
        }
    }

    public view410() {
        const self = this;
        try {
            if (this.userService.isLoggedIn()) {
                if (this.userService.getRole() === 'ROLE_CUSTOMER') {
                    // ga('send', 'event', 'SiteError410', 'customer', this.userService.getData().userId, {'nonInteraction': 1});
                } else if (this.userService.getRole() === 'ROLE_CONSULTANT') {
                    // ga('send', 'event', 'SiteError410', 'consultant', this.userService.getData().userId, {'nonInteraction': 1});
                }
            } else {
                // ga('send', 'event', 'SiteError410', 'visitor', 'none', {'nonInteraction': 1});
            }
        } catch (e) {
            self.utilService.setTimeout(function () { self.view410(); }, 1000);
        }
    }

    public accessDenied() {
        const self = this;
        try {
            if (this.userService.isLoggedIn()) {
                if (this.userService.getRole() === 'ROLE_CUSTOMER') {
                    // ga('send', 'event', 'AccessDenied', 'customer', this.userService.getData().userId, {'nonInteraction': 1});
                } else if (this.userService.getRole() === 'ROLE_CONSULTANT') {
                    // ga('send', 'event', 'AccessDenied', 'consultant', this.userService.getData().userId, {'nonInteraction': 1});
                }
            } else {
                // ga('send', 'event', 'AccessDenied', 'visitor', 'none', {'nonInteraction': 1});
            }
        } catch (e) {
            self.utilService.setTimeout(function () { self.accessDenied(); }, 1000);
        }

    }

    public async logOut() {
        const self = this;
        try {
            if (this.userService.isLoggedIn()) {
                if (this.userService.getRole() === 'ROLE_CUSTOMER') {
                    // ga('send', 'event', 'SessionExpiry', 'customer', this.userService.getData().userId, {'nonInteraction': 1});
                    await this.googleAnalyticsTag('SessionExpiry', 'customer', `${this.userService.getData().userId}`);
                } else if (this.userService.getRole() === 'ROLE_CONSULTANT') {
                    // ga('send', 'event', 'SessionExpiry', 'consultant', this.userService.getData().userId, {'nonInteraction': 1});
                    await this.googleAnalyticsTag('SessionExpiry', 'consultant', `${this.userService.getData().userId}`);
                }
            } else {
                // ga('send', 'event', 'SessionExpiry', 'visitor', 'none', {'nonInteraction': 1});
                await this.googleAnalyticsTag('SessionExpiry', 'visitor', `none`);
            }
        } catch (e) {
            self.utilService.setTimeout(function () { self.logOut(); }, 1000);
        }
    }

    public async visitorReadingAttempt(consultantId, type) {
        const self = this;
        try {
            // ga('send', 'event', 'VisitorReadingAttempt', type, consultantId);
            await this.googleAnalyticsTag('VisitorReadingAttempt', type, consultantId + '');
        } catch (e) {
            self.utilService.setTimeout(function () { self.visitorReadingAttempt(consultantId, type); }, 1000);
        }
    }
    public async customerReadingAttempt(consultantId, type) {
        const self = this;
        try {
            // ga('send', 'event', 'CustomerReadingAttempt', type, consultantId);
            await this.googleAnalyticsTag('CustomerReadingAttempt', type, consultantId + '');
        } catch (e) {
            self.utilService.setTimeout(function () { self.customerReadingAttempt(consultantId, type); }, 1000);
        }
    }
    public async contactMeAttempt(consultantId) {
        const self = this;
        try {
            // ga('send', 'event', 'contactMe', 'clicked', consultantId);
            await this.googleAnalyticsTag('contactMe', 'clicked', consultantId + '');
        } catch (e) {
            self.utilService.setTimeout(function () { self.contactMeAttempt(consultantId); }, 1000);
        }
    }

    public async viewAllReaders() {
        const self = this;
        try {
            // ga('send', 'event', 'ReaderListing', 'ViewAllReaders');
            await this.googleAnalyticsTag('ReaderListing', 'ViewAllReaders', ``);
        } catch (e) {
            self.utilService.setTimeout(function () { self.viewAllReaders(); }, 1000);
        }
    }

    //region No Use
    /**
     * It looks is not use on GA or GTM
     * @param event
     */
    public async referralTrackEvent(event) {
        // this.$gaService.gtag('event', 'referralTrackEvent');
        // const gtmTag = {
        //     event: 'referralTrackEvent'
        // };
        // this.gtmService.pushTag(gtmTag);
        event.event = 'referralTrackEvent';
        await this.gtmService.pushTag(event);
    }

    /**
     * Is not required to fire anything due that the goal is reach when the page is visit
     */
    public readerRegistrationStart() {
        // const self = this;
        // // this.$gaService.gtag('Registration', 'Reader', 'start');
        // try {
        //     // ga('send', 'event', 'Registration', 'Reader', 'start');
        // } catch (e) {
        //     setTimeout(function () { self.readerRegistrationStart() }, 1000);
        // }
    }

    public async readerRegistrationFinish(userId: string) {
        const purchase = {
            'event': 'reader-registration',
            'status': 'finish',
            'eventLabel': userId,

        };
        await this.gtmService.pushTag(purchase);
    }

    /**
     * It looks is not handle by tag manager, it is executed by the back-end
     */
    public async paypalMethodUsed() {
        // await this.gtmService.pushTag({
        //     'event': 'Google Analytics',
        //     'category': 'payment-method-PayPal',
        //     'action': 'promotion-used'
        // });
        await this.gtmService.pushTag({'event': 'payment-method-PayPal'});
        await this.gtmService.pushTag({'event': 'promotion-used'});
    }

    /**
     * It looks is not used on GA or GTM
     */
    public async creditCardUsed() {
        await this.gtmService.pushTag({'event': 'payment-method-CC'});
    }
    //endregion

    //LEGACY DOES  NOT WORK IN GA4
    private async googleAnalyticsTag(category, action, label) {
        await this.gtmService.pushTag({
            'event': 'Google Analytics',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label,
            'eventPlatform': 'Angular'
        });
    }

    /**
     * It looks is not use on GA OR gtm
     */
    public async promotionUsed() {
        await this.gtmService.pushTag({'event': 'promotion-used'});
    }

    //LEGACY, DOES NOT WORK ON GA4
    public async sendEvent(category: string, action: string, label: string, value: string) {
        await this.gtmService.pushTag({
            'event': 'GAAngular',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label,
            'eventValue': value
        });
    }

    public async purchase(transactionId, value, productCode, product, currency) {
        // This is for GA4
        const purchase = {
            'event': 'purchase',
            'ecommerce': {
                'transaction_id': transactionId,
                'value': value,
                'items': [{
                    'item_id': productCode,
                    'item_name': product,
                    'price': value,
                    'quantity': 1,
                    'item_brand': 'Promotions',
                    'currency': currency
                }],
                'currency': currency
            }

        };
        await this.gtmService.pushTag(purchase);
    }
}



