import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {Reader} from '../modules/shared/entity/reader';
import {ReaderService} from '../modules/shared/services/reader-service';
import {AllEmiterService} from '../services/all-emiter.service';
import {Recent} from '../entity/recent';
import {FeedbackService} from '../services/feedback.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReaderProfileResolverService implements Resolve<Observable<ReadersProfileResolverData>> {
    public data: ReadersProfileResolverData;

    url: string;

    constructor(
        private readerService: ReaderService,
        private feedbackService: FeedbackService,
        private route: ActivatedRoute,
        private router: Router,
        private allEmiterService: AllEmiterService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.allEmiterService.onWaitChange(true);
        const self = this;
        self.data = new ReadersProfileResolverData();
        const readerName = route.paramMap.get('reader').replace(/\b\w/g, l => l.toLowerCase());

        const values2 = forkJoin([
                self.readerService.getIdByName(readerName),
            ]
        ).pipe(
            map( ([id]) => {
                self.data.readerId = id;
                if (!id) {
                    self.router.navigate(['/']);
                    return;
                }
                return id;

            }),
            switchMap((id) => {
                // const readerProfile = await self.readerService.getReaderById(id).toPromise();
                return self.readerService.getReaderById(id).pipe(
                    map( (res) => {
                        // console.og('readerProfile', res);
                        // const readerProfile = res;
                        return res;
                    }),
                    switchMap((res) => {
                        const values = forkJoin([
                                // self.readerService.getReaderById(id).toPromise(),
                                self.feedbackService.getRecentByReader(id),
                                self.feedbackService.getReaderTop(id),
                                self.feedbackService.getReaderAll(id, 1, 6)
                            ]
                        ).pipe(
                            map(([b, c, d]) => {
                                self.allEmiterService.onWaitChange(false);
                                self.data.reader = res;
                                self.data.feedbackData = b;
                                self.data.feedbackTop = c;
                                self.data.feedbackBottom = d;
                                // }
                                return self.data;
                            })
                        );
                        return values;
                    })
                );
            }),
        );
        return values2;
    }
}

export class ReadersProfileResolverData {
    public reader: Reader;
    public feedbackData: Recent[];
    public feedbackTop: Recent[];
    public feedbackBottom: Recent[];
    public readerId: number;


    constructor() {
    }
}
