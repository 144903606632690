import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {BackRouter} from '../modules/shared/services/back-router';
import {LoginResponse} from '../entity/login-response';
import {LoginRequest} from '../entity/login-request';
import {CookieService} from 'ng2-cookies';
import {NGXLogger} from 'ngx-logger';
import {UserDetails} from '../modules/shared/entity/user-details';
import {NavigationEnd, Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {LoginFbRequest} from '../entity/login-fb-request';
import {PaymentService} from '../modules/customer/services/payment.service';
import {SubSink} from 'subsink';
// import * as Sentry from '@sentry/browser';
import {ElectronService} from './electron.service';
import {AllEmiterService} from './all-emiter.service';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs';
import {UtilService} from './util.service';
import {AddFundsAttemptsService} from './nestjs/logs/add-funds-attemps/add-funds-attempts.service';
import {AddFundState} from './nestjs/logs/add-funds-attemps/enum/add-fund-state.enum';
import {CanShowAddFundsStatus} from './nestjs/user/dto/can-show-add-funds.dto';
import {AlertService} from './alert.service';
import {UserNestService} from './nestjs/user/user-nest.service';
import {ErrorService} from './error.service';
import {PromotionEntity} from './nestjs/promotion/entities/promotion.entity';

@Injectable({
    providedIn: 'root',
})
export class UserService implements OnDestroy {
    data: UserDetails;
    private subs = new SubSink();

    constructor(private httpClient: HttpClient,
                private br: BackRouter,
                private cookie: CookieService,
                private logger: NGXLogger,
                private bsModalService: BsModalService,
                private paymentService: PaymentService,
                private electronService: ElectronService,
                private allEmiterService: AllEmiterService,
                private utilService: UtilService,
                private router: Router,
                private addFundsAttemptsService: AddFundsAttemptsService,
                private userNestService: UserNestService,
                private errorService: ErrorService,
                private alertService: AlertService) {
        const self = this;
        this.subs.sink = this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe((event) => {
                try {

                } catch (e) {
                    console.error(e);
                }
            });
    }


    /**
     * Validate if user passed step 2 of registration
     * This function was separated to be use in other areas of the system such as account details page, where user
     * can not access if they have not done step2, but they can if they have done step2, but not step3
     */
    async checkPassStep2() {
        if (this.getStep() === 2) {
            // tslint:disable-next-line:max-line-length
            const component: any = await import('../modules/shared/components/registration-step2/registration-step2.component').then(cp => cp.RegistrationStep2Component);
            const registerModalRef = this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return registerModalRef;
        }
        return true;
    }

    /**
     * Validate if user passed step 3 of registration
     * This function is separated to be use in other areas of the system
     */
    async checkPassStep3(activeTab?: number) {
        const self = this;
        if (this.getStep() === 3) {
            // tslint:disable-next-line:max-line-length
            // Get the last payment system use https://lifereader.atlassian.net/browse/LA-321
            let lastPaymentSystem: any = self.utilService.localStorageGetItem('lastPaymentSystem');
            lastPaymentSystem = lastPaymentSystem == null ? 0 : lastPaymentSystem;
            if (typeof activeTab !== 'undefined') {
                lastPaymentSystem = activeTab;
            }
            const component: any = await import('../modules/shared/components/registration-step3/registration-step3.component')
                .then(cp => cp.RegistrationStep3Component);
            const registerModalRef = this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
                initialState: {activeTab: lastPaymentSystem},
            });
            return registerModalRef;
        }
        return true;
    }

    async checkRegistration() {
        if (!this.isLoggedIn()) {
            this.addFundsAttemptsService.addEvent(AddFundState.IS_NOT_LOGGED_IN, '');
            // tslint:disable-next-line:max-line-length
            const component: any = await import('../modules/shared/components/registration/registration.component').then(cp => cp.RegistrationComponent);
            const registerModalRef = this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return registerModalRef;
        }

        const step2 = await this.checkPassStep2();
        if ( step2 !== true) {
            await this.addFundsAttemptsService.addEvent(AddFundState.STEP_2, '');
            return step2;
        }

        const step3 = await this.checkPassStep3();
        if ( step3 !== true) {
            await this.addFundsAttemptsService.addEvent(AddFundState.STEP_3, '');
            return step3;
        }
        return true;
    }


    async firstTimeAddingFunds(activeTab?: number, hasTransactions: boolean = true) {
        const self = this;
        if (this.getData()) {
            if (this.data.showPromo || (!this.data.showPromo && !hasTransactions)) {
                const promotionsCC = await this.paymentService.getPromotionsRegistrationCC(true).toPromise();
                const promotions = await this.paymentService.getPromotionsRegistrationPaypal(true).toPromise();

                // Avoid showing not welcome offers when the promotions are null due to the currencies does not match
                // if that happens registration step 3 will reload user information to see if currencies match
                // if (promotionsCC && promotions) {
                    // Get the last payment system use https://lifereader.atlassian.net/browse/LA-321
                    // https://lifereader.atlassian.net/browse/LA-1037
                    let lastPaymentSystem: any = this.utilService.localStorageGetItem('lastPaymentSystem');
                    lastPaymentSystem = lastPaymentSystem == null ? 0 : lastPaymentSystem;
                    if (activeTab) {
                        lastPaymentSystem = activeTab;
                    }

                    const component: any = await import('../modules/shared/components/registration-step3/registration-step3.component')
                        .then(cp => cp.RegistrationStep3Component);
                    const modal = this.bsModalService.show(component, {
                        class: 'modal-dialog-steps',
                        backdrop: true,
                        keyboard: true,
                        animated: false,
                        initialState: {
                            activeTab: lastPaymentSystem,
                            promotionsCC,
                            promotions,
                            paygValue: this.data.paygOn
                        },
                    });
                    // modal.content.promotionsCC = promotionsCC;
                    // modal.content.promotions = promotions;
                    return false;
                // }

            } else {
                return true;
            }
        } else {
            throw new Error('No user data');
        }


    }

    async getOffersByUser() {
        const canShowAddFundsDto = await this.userNestService.showAddFunds();
        if (canShowAddFundsDto.result === CanShowAddFundsStatus.SHOW_PROMO) {
            const promotionsCC = await this.paymentService.getPromotionsRegistrationCC(true).toPromise();
            const promotions = await this.paymentService.getPromotionsRegistrationPaypal(true).toPromise();
            canShowAddFundsDto.promotionList[0] = ({
                name: 'Credit Card',
                promotions: promotionsCC as PromotionEntity[]
            });
            canShowAddFundsDto.promotionList[1] = ({
                name: 'Pay Pal',
                promotions: promotions as PromotionEntity[]
            });
        }
        return canShowAddFundsDto;
    }

    async checkRegistrationPaypal() {
        if (!this.isLoggedIn()) {
            // tslint:disable-next-line:max-line-length
            const component: any = await import('../modules/shared/components/registration/registration.component')
                .then(cp => cp.RegistrationComponent);
            this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return;
        }

        if (this.getStep() === 2) {
            // tslint:disable-next-line:max-line-length
            const component: any = await import('../modules/shared/components/registration-step2/registration-step2.component')
                .then(cp => cp.RegistrationStep2Component);
            this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
            });
            return;
        }

        if (this.getStep() === 3) {
            // tslint:disable-next-line:max-line-length
            const component: any = await import('../modules/shared/components/registration-step3/registration-step3.component')
                .then(cp => cp.RegistrationStep3Component);
            const bsModalRef = this.bsModalService.show(component, {
                class: 'modal-dialog-steps',
                backdrop: true,
                keyboard: true,
                animated: false,
                initialState: {
                    activeTab: 1
                }
            });
            // bsModalRef.content.activeTab = 1;
            return;
        }
        return true;
    }

    async firtsTimeAddingFundsPaypal() {
        if (this.getData()) {
            if (this.data.showPromo) {
                // tslint:disable-next-line:max-line-length
                const component: any = await import('../modules/shared/components/registration-step3/registration-step3.component').then(cp => cp.RegistrationStep3Component);
                const bsModalRef = this.bsModalService.show(component, {
                    class: 'modal-dialog-steps',
                    backdrop: true,
                    keyboard: true,
                    animated: false,
                    initialState: {
                        activeTab: 1
                    }
                });
                // bsModalRef.content.activeTab = 1;
                return false;
            } else {
                return true;
            }
        } else {
            throw new Error('No user data');
        }


    }

    isLoggedIn(): boolean {
        const self = this;
        // tslint:disable-next-line:no-console
        return !!self.utilService.localStorageGetItem('uid');
        // return !!this.cookie.get('auth');
    }

    getRole() {
        const self = this;
        const role = self.utilService.localStorageGetItem('role');
        if (role) {
            return role;
        }
        try {
            this.getData();
            return this.data.role;
        } catch (e) {
            // console.error(e);
            return '';

        }
    }

    getAuth(): UserDetails {
        const self = this;
        // if (!this.cookie.get('auth')) {
        if (!self.utilService.localStorageGetItem('userDetails')) {
            self.utilService.localStorageRemoveItem('role');
            self.utilService.localStorageRemoveItem('testSound');
            self.utilService.localStorageRemoveItem('uid');
            return null;
        }
        const uId = self.utilService.localStorageGetItem('uid');
        if (uId) {
            // const obj: RegistrationResponse = JSON.parse(this.cookie.get('auth'));
            const obj: UserDetails  = JSON.parse(self.utilService.localStorageGetItem('userDetails'));
            this.data = obj;
            if (!this.data.userId) {
                this.data = null;
                return null;
                // throw new Error('User not auth');
            }
            return this.data;
        } else {
            return null;
            // throw new Error('User not logged');
        }

    }

    getStep() {
        if (!this.getAuth()) {
            return null;
        }
        return (this.data) ? this.data.regStep : null;
    }

    getPaygWelcome() {
        if (!this.getAuth()) {
            return null;
        }
        return (this.data) ? this.data.showPaygOnWelcome : null;
    }

    getPaygAddFunds() {
        if (!this.getAuth()) {
            return null;
        }
        return (this.data) ? this.data.showPaygOnAddFunds : null;
    }

    doLogin(data: LoginRequest) {
        const self = this;
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/login', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        return self.successLogin(obj);
                    },
                ),
            );
    }

    successLogin(obj: Object) {
        this.electronService.setDockMenu();
        if (!obj) {
            return obj;
        }
        const res = plainToClass(LoginResponse, obj);
        this.data = res.userDetails;
        this.processDetailsData(res.userDetails);
        return res;
    }

    doLoginFB(data: LoginFbRequest) {
        const self = this;
        self.utilService.localStorageSetItem('registration', 'facebook');
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/login/social', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                        const res = plainToClass(LoginResponse, obj);
                        // this.cookie.set('auth', res.toString());
                        self.utilService.localStorageSetItem('userDetails', JSON.stringify(res.userDetails));
                        this.data = res.userDetails;
                        self.successLogin(obj);
                        return res;
                    },
                ),
            );
    }

    horoscopeNotification(data) {
        return this.httpClient
            .post(this.br.getJavaWebServicesUrl() + '/rest/notification/horoscope', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                    },
                ),
            );
    }

    contact(data) {
        return this.httpClient
            .post(this.br.getJavaWebServicesUrl() + '/rest/application/contact', data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                        if (!obj) {
                            return obj;
                        }
                    },
                ),
            );
    }

    signOut() {
        return this.httpClient.post(this.br.getJavaWebServicesUrl() + '/rest/user/logout', {}, {headers: { ignoreLoadingBar: '' }});
    }

    phones() {
        return this.httpClient.get(this.br.getJavaWebServicesUrl() + '/rest/consultant/phones', {headers: { ignoreLoadingBar: '' }});
    }

    getSelfBusy() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/consultant/selfbusy'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { selfBusy: boolean }) => {
                return obj;
            }));
    }

    addSelfBusy() {
        return this.httpClient.post(this.br.makeBackUrl('/rest/consultant/selfbusy'), {}, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: { selfBusy: boolean }) => {
                return obj;
            }));
    }

    getDetails(): Observable<any> {
        const self = this;
        return this.httpClient.get(this.br.makeBackUrl('/rest/user/details'), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map(
                    (obj: Object) => {
                        return self.processDetailsData(obj);
                    }),
                catchError((err => {
                    throw err;
                }))
            );
    }

    processDetailsData(obj: Object) {
        const self = this;
        const prevUserData = self.data;
        self.data = plainToClass(UserDetails, obj);
        if (self.data.userId === null) {
            if (prevUserData && prevUserData.userId) {
                self.data = prevUserData;
            }
        }
        if (self.data && self.data.userId !== null) {
            this.allEmiterService.onCurrencyChance(self.data.currency);
            self.utilService.localStorageSetItem('currency', JSON.stringify(self.data.currency) + '');
            self.utilService.localStorageSetItem('fullBalance', JSON.stringify(self.data.fullBalance) + '');
            this.allEmiterService.onFundChange(self.data.fullBalance);
            self.utilService.localStorageSetItem('countryId', JSON.stringify(self.data.defaultCountryCode) + '');
            self.utilService.localStorageSetItem('role', self.data.role);
            self.utilService.localStorageSetItem('uid', self.data.userId + '');
            self.utilService.localStorageSetItem('userTimeZone', self.data.olsonTimezone + '');
            self.utilService.localStorageSetItem('userDetails', JSON.stringify(self.data));
        }

        return self.data;

    }

    getDetailsToPromise(): Promise<any> {
        const self = this;
        const prevUserData = self.data;
        return this.httpClient.get(this.br.makeBackUrl('/rest/user/details'), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Object) => {
                    return self.processDetailsData(obj);
            }),
                catchError((err => {
                    throw err;
                }))
                ).toPromise();
    }

    notifyForAnonymous(data) {
        return this.httpClient
            .post(this.br.makeBackUrl(`/rest/notification/reader/${data.readerId}/online`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: Object) => {
                return obj;
            }));
    }

    forgetPassword(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/user/forgetPassword`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: Object) => {
                return obj;
            }));
    }

    resetPassword(data) {
        return this.httpClient.post(this.br.makeBackUrl(`/rest/user/resetPassword`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: Object) => {
                return obj;
            }));
    }

    getFooterLinks() {
        return this.httpClient.get(this.br.makeBackUrl('/rest/sitemap/footer'), {headers: { ignoreLoadingBar: '' }})
            .pipe(map((obj: Object) => {
                return obj;
            }));
    }

    getData() {
        const prevUserData = this.data;
        if (this.isLoggedIn()) {
            if (this.data && this.data.userId) {
                return this.data;
            } else {
                if (this.getAuth()) {
                    return this.data;
                } else {
                    if (prevUserData.userId) {
                        this.data = prevUserData;
                    }
                    throw new Error('No user data');
                }
            }
        } else {
            throw new Error('No user data: Not Logged In');
        }

    }

    ping() {
        return this.httpClient
            .get(this.br.getJavaWebServicesUrl() + '/rest/common/ping', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                catchError((error) => of({})));

    }
    ping2() {
        return this.httpClient
            .get(this.br.getJavaWebServicesUrl() + '/rest/common/ping2', {headers: { ignoreLoadingBar: '' }})
            .pipe(
                catchError((error) => of({})));

    }

    getPromotionAds() {
        this.logger.debug('Trying to get promotion ads');
        const self = this;

        try {
            this.getData();
            this.logger.debug('Data', self.data);
            this.logger.debug('role', self.data.role);
            if (self.data && self.data.role === 'ROLE_CUSTOMER') {
                this.logger.debug('Getting them');
                return this.httpClient.get(this.br.makeBackUrl('/rest/promotion/adds'), {headers: {ignoreLoadingBar: ''}});
            }
            this.logger.debug('NO ads because is not a customer');
            return of({});
        } catch (e) {
            this.logger.error('Error getting ads', e);
            return of({});
        }
        // if (self.data && self.data.role === 'ROLE_CUSTOMER') {
        //     return this.httpClient.get(this.br.makeBackUrl('/rest/promotion/adds'), {headers: { ignoreLoadingBar: '' }});
        // } else {
        //     return of({});
        // }

    }

    gaClientId(clientId: string) {
        const self = this;
        if (self.data && self.data.role === 'ROLE_CUSTOMER') {
            return this.httpClient.put(
                this.br.getJavaWebServicesUrl() + '/rest/user/gasession',
                {clientId},
                {headers: { ignoreLoadingBar: '' }});
        } else {
            return of({});
        }

    }

    public async showAddFunds(message: string, activeTab?: number, selectedOffer: number = -1) {
        try {
            this.utilService.startAddFundsAttempt();
            await this.addFundsAttemptsService.create(message);
            const result = await this.userNestService.showAddFunds();
            if (result.result !== CanShowAddFundsStatus.CAN_SHOW_FUNDS) {
                switch (result.result) {
                    case CanShowAddFundsStatus.IS_BLOCKED:
                        this.addFundsAttemptsService.addEvent(AddFundState.USER_BLOCKED, '');
                        this.alertService.alert({
                            title: ``,
                            body: 'We are sorry but adding funds is not possible at this time. Please contact Support.',
                            type: 'info',
                        });
                        break;
                    case CanShowAddFundsStatus.STEP_1:
                        await this.checkPassStep2();
                        break;
                    case CanShowAddFundsStatus.STEP_2:
                        this.addFundsAttemptsService.addEvent(AddFundState.STEP_2, '');
                        await this.checkPassStep2();
                        break;
                    case CanShowAddFundsStatus.STEP_3:
                        this.addFundsAttemptsService.addEvent(AddFundState.STEP_3, '');
                        await this.checkPassStep3(activeTab);
                        break;
                    case CanShowAddFundsStatus.SHOW_PROMO:
                        this.addFundsAttemptsService.addEvent(AddFundState.FIRST_TIME_ADDING_FUNDS, '');
                        await this.firstTimeAddingFunds(activeTab, result.hasTransactions);
                        break;
                    case CanShowAddFundsStatus.IS_BUSY:
                        this.alertService.alert({
                            title: 'Funds',
                            body: 'You can not add funds at this time',
                            type: 'info',
                        });
                        break;

                }
                return;
            } else {
                try {
                    let lastPaymentSystem: any = this.utilService.localStorageGetItem('lastPaymentSystem');
                    lastPaymentSystem = lastPaymentSystem == null ? 0 : lastPaymentSystem;
                    /**
                     * This way we can activate the cc tab when we send the 0
                     */
                    if (typeof activeTab !== 'undefined') {
                        lastPaymentSystem = activeTab;
                    }

                    // console.og('lastPaymentSystem', lastPaymentSystem);
                    const component: any =
                        await import('../modules/shared/components/modals/payments-modal/payments-modal.component')
                            .then(cp => cp.PaymentsModalComponent);
                    this.bsModalService.show(component, {
                        animated: false,
                        backdrop: true,
                        keyboard: false,
                        ignoreBackdropClick: true,
                        initialState: {
                            data: result,
                            activeTab: lastPaymentSystem,
                            currentPaymentId: selectedOffer,
                        },
                    });
                } catch (e) {
                    this.addFundsAttemptsService.addEvent(AddFundState.ERROR_SHOWING_MODAL, e);
                    this.logger.error('Error showing funds', e);
                    location.reload();
                }
            }
        } catch (error) {
            this.alertService.alert({
                title: 'Funds',
                body: 'We are sorry but adding funds is not possible at this time. Please contact Support.',
                type: 'info',
            });
            this.addFundsAttemptsService.addEvent(AddFundState.ERROR_GETTING_DATA, error);
            this.logger.error('Error add funds', error);
            // Client Error
            const messageErr = this.errorService.getClientMessage(error);
            const stackTrace = this.errorService.getClientStack(error);

            this.logger.error('Stack ' + messageErr, stackTrace);
        }
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
