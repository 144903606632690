import 'reflect-metadata';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
// import * as Sentry from '@sentry/angular-ivy';



if (environment.production) {
    // Sentry.init({
    //     dsn: 'https://808860d3fcd8034cff56e7ff67206569@o382013.ingest.sentry.io/4505626565017600',
    //     integrations: [
    //         new Sentry.BrowserTracing({
    //             routingInstrumentation: Sentry.routingInstrumentation,
    //         }),
    //         // Registers the Replay integration,
    //         // which automatically captures Session Replays
    //         new Sentry.Replay(),
    //     ],
    //     tracePropagationTargets: ['localhost', 'lfr.com', 'lfr.com:4200'],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    //     // Session Replay
    //     replaysSessionSampleRate: 1,
    //     replaysOnErrorSampleRate: 1.0,
    // });
    enableProdMode();
}


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(success => {
        // console.og(`Bootstrap success`);
    })
    .catch(err => console.error(err));
