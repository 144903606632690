import {EventEmitter, Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';
import {BackRouter} from './back-router';
import {Observable} from 'rxjs/internal/Observable';
import {AllReadersResponse} from '../entity/all-readers-response';
import {plainToClass} from 'class-transformer';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {FeaturedReadersResponse} from '../entity/featured-readers-response';
import {Reader} from '../entity/reader';
import {Category} from '../../../entity/category';
import {StartCallResponse} from '../entity/start-call-response';
import {SearchReader} from '../entity/search-reader';
import {EMPTY, forkJoin, throwError} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {CountryResponse} from '../../consultant/entity/country-response';
import {StartCallTwilioResponse} from '../entity/start-call-twilio-response';
import {UtilService} from '../../../services/util.service';
import {isPlatformBrowser} from '@angular/common';
import {NGXLogger} from 'ngx-logger';

export const READERS_SHORT = 'readers_short';
export const SELECTED_READER = 'selected_reader';

@Injectable({
    providedIn: 'root',
})
export class ReaderService {

    public shortReaders: { id: number, name: string }[];
    public onUpdateReader: EventEmitter<Reader> = new EventEmitter();
    private isBrowser: boolean = isPlatformBrowser(this.platformId);
    constructor(
        private httpClient: HttpClient,
        private backRouter: BackRouter,
        private utilService: UtilService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private ngxLogger: NGXLogger
    ) {
    }

    async getIdByName(name: string) {
        if (this.isBrowser) {
            this.shortReaders = this.loadFromLocalStorage();
            if (!this.shortReaders) {
                const data = await this.getReaders().toPromise();
                const shortData = data.map((reader: Reader) => {
                    return {
                        id: reader.id,
                        name: reader.nickname,
                    };
                });
                this.saveLocalStorage(shortData);
            }
            this.shortReaders = this.loadFromLocalStorage();
            let foundReader = this.shortReaders.find(item => item.name.toLowerCase().replace(/ /g, '-') === name);
            // It is possible it is a new reader that has not been loaded yet
            if (!foundReader) {
                const data = await this.getReaders().toPromise();
                const shortData = data.map((reader: Reader) => {
                    return {
                        id: reader.id,
                        name: reader.nickname,
                    };
                });
                this.saveLocalStorage(shortData);
            }
            this.shortReaders = this.loadFromLocalStorage();
            foundReader = this.shortReaders.find(item => item.name.toLowerCase().replace(/ /g, '-') === name);
            if (!foundReader) {
                return null;
            }
            return foundReader.id;
        } else {
            const data = await this.getReaders().toPromise();
            const shortData = data.map((reader: Reader) => {
                return {
                    id: reader.id,
                    name: reader.nickname,
                };
            });
            this.shortReaders = shortData;
            const foundReader = this.shortReaders.find(item => item.name.toLowerCase().replace(/ /g, '-') === name);
            if (!foundReader) {
                return null;
            }
            return foundReader.id;
        }

    }

    getPromotionCalls() {
        return this.httpClient.get(this.backRouter.makeBackUrl('/rest/promotion/calls'), {headers: { ignoreLoadingBar: '' }});
    }

    getPromotionRegistration() {
        return this.httpClient.get(this.backRouter.makeBackUrl('/rest/promotion/registration'), {headers: { ignoreLoadingBar: '' }});
    }


    getPromotionRegistrationCC() {
        return this.httpClient.get(this.backRouter.makeBackUrl('/rest/promotion/registration/cc'), {headers: { ignoreLoadingBar: '' }});
    }

    getPromotionRegistrationPayPal() {
        return this.httpClient.get(this.backRouter.makeBackUrl('/rest/promotion/registration/paypal'), {headers: { ignoreLoadingBar: '' }});
    }

    getReaders(): Observable<Reader[]> {
        const config = { offset: 0,  max: 30, moveBusyReaders: true };
        const config2 = { offset: 30,  max: 30, moveBusyReaders: true};
        const config3 = { offset: 60,  max: 30, moveBusyReaders: true};
        const config4 = { offset: 90,  max: 30, moveBusyReaders: true};

        return  forkJoin([
                this.getReadersPopular(config),
                this.getReadersPopular(config2),
                this.getReadersPopular(config3),
                this.getReadersPopular(config4)
            ]
        ).pipe(
            map(([
                first,
                     second,
                     thrird,
                     fourth,
                     ]) => {
                let auxReaders: Reader[] = first.entities;
                auxReaders = auxReaders.concat(second.entities);
                auxReaders = auxReaders.concat(thrird.entities);
                auxReaders = auxReaders.concat(fourth.entities);
                return auxReaders;
            })
        );

    }

    getPublishedReaders(): Observable<Reader[]> {
        return this.httpClient.get(this.backRouter.makeBackUrl('/rest/reader/published'), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((objs: Reader[]) => {
                        return plainToClass<Reader, Object>(Reader, objs);
                    },
                ),
            );
    }

    getReadersPopular(config): Observable<AllReadersResponse> {
        return this.httpClient.get(this.backRouter.getJavaWebServicesUrl() + '/rest/reader/popular', {
        // return this.httpClient.get(this.backRouter.getNestJsUrl() + '/user-consultant/popular', {
            params: config,
            headers: { ignoreLoadingBar: '' }
        })
            .pipe(
                // timeout(2000),
                map((obj: AllReadersResponse) => {
                        const objr = plainToClass(AllReadersResponse, obj);
                        return objr;
                    },
                ),
                catchError( err => {
                    console.error(err.message);
                    return throwError('TimeOut');
                })
            );
    }

    getFeaturedReaders(): Observable<FeaturedReadersResponse> {
        return this.httpClient.get(this.backRouter.getJavaWebServicesUrl() + '/rest/reader/popular', {
        // return this.httpClient.get(this.backRouter.getNestJsUrl() + '/user-consultant/popular', {
            params: {
                moveBusyReaders: 'false',
                offset: '0',
                max: '3',
            },
            headers: {  }
        })
            .pipe(
                map((obj: FeaturedReadersResponse) => {
                        return plainToClass(FeaturedReadersResponse, obj);
                    },
                ),
            );
    }

    getReaderById(readerId: number): Observable<Reader> {
        // tslint:disable-next-line:no-console
        return this.httpClient.get(this.backRouter.makeBackUrl(`/rest/reader/${readerId}/profile`), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Reader) => {
                        return plainToClass(Reader, obj);
                    },
                ),
            );
    }

    searchReaders(name: string, justPublished: boolean = true) {
        return this.httpClient.get(this.backRouter.makeBackUrl(`/rest/reader/search?search=${name}&justPublished=${justPublished}`), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: SearchReader) => {
                        return plainToClass(SearchReader, obj);
                    },
                ),
            );
    }


    getReaderSnippet(readerId) {
        return this.httpClient.get(this.backRouter.makeBackUrl(` /rest/reader/${readerId}/snippet`), {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: Reader) => {
                        return plainToClass(Reader, obj);
                    },
                ),
            );
    }

    saveLocalStorage(data) {
        const self = this;
        self.utilService.localStorageSetItem(READERS_SHORT, JSON.stringify({
            timestamp: new Date().getTime(),
            data: data,
        }));
    }

    loadFromLocalStorage() {
        const self = this;
        const string = self.utilService.localStorageGetItem(READERS_SHORT);
        if (!string) {
            return null;
        }
        return JSON.parse(string).data;
    }

    saveSelectedReaderLocalStorage(data) {
        const self = this;
        self.utilService.localStorageSetItem(SELECTED_READER, JSON.stringify({
            id: data,
        }));
    }

    loadSelectedReaderFromLocalStorage() {
        const self = this;
        const string = self.utilService.localStorageGetItem(SELECTED_READER);
        if (!string) {
            return null;
        }
        return JSON.parse(string).id;
    }

    removeSelectedReaderFromStorage() {
        const self = this;
        self.utilService.localStorageRemoveItem(SELECTED_READER);
    }

    getCategories(): Observable<Category[]> {
        const self = this;
        const categories = self.utilService.localStorageGetItem('categories');
        if (categories) {
            return of(plainToClass(CountryResponse, JSON.parse(categories)) as any);
        } else {
            return EMPTY;
        }
    }

    saveCategories(categories: Category[]): Category[] {
        const self = this;
        // Remove category Fertility Readings (id 50)
        for (const category of categories) {
            const subcategories = category.subCategories.filter(function(subcategory, index, arr) {
                if (subcategory.id === 50) { return false; }
                return true;
            });
            category.subCategories = subcategories;
        }
        self.utilService.localStorageSetItem('categories', JSON.stringify(categories));
        return categories;
    }

    getCategoryList(): Observable<Category[]> {
        const self = this;
        return this.httpClient.get(this.backRouter.getNestJsUrl() + '/category/all', {
            params: {
                includeSubCategories: 'true',
            },
            headers: { ignoreLoadingBar: '' }
        })
            .pipe(
                map((obj: any) => {
                    // Categories obtain
                        let categories2 = plainToClass(Category, obj.data) as any;
                        categories2 = self.saveCategories(categories2);
                        return categories2;
                    },
                ),
            );
    }

    startReading(data) {
        let lastResponse: HttpEvent<any> = null;
        let error: HttpErrorResponse;
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/init`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                tap((response: HttpEvent<any>) => {
                    lastResponse = response;
                    if (response.type === HttpEventType.Response) {
                    }
                }),
                catchError((err: any) => {
                    error = err;
                    // TODO: error handling if required
                    return throwError(err);
                }),
                finalize(() => {
                    if (lastResponse.type === HttpEventType.Sent && !error) {
                        // last response type was 0, and we haven't received an error
                    } else {

                    }
                }),
                map((obj: any) => {
                        return plainToClass(StartCallResponse, obj) as any;
                    },
                ),
            );
    }



    startCost(data) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/cost`), data, {headers: { ignoreLoadingBar: '' }})
            .pipe(
                map((obj: any) => {
                        return plainToClass(StartCallResponse, obj) as any;
                    },
                ),
            );
    }

    closeWarningMessage() {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/call/free-denied-shown-closed/true`),
            {}, {headers: { ignoreLoadingBar: '' }});
    }

    startCall(readingId) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/${readingId}/call/start`),
            {}, {headers: { ignoreLoadingBar: '' }});
    }

    startCallTwilio(readerId, customerPhoneNumber) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/call/start-call`),
            {readerId, customerPhoneNumber}, {headers: { ignoreLoadingBar: '' }});
    }

    confirmCallTwilio(callConfig: StartCallTwilioResponse) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/call/confirm-call`),
            callConfig, {headers: { ignoreLoadingBar: '' }});
    }
    doCallBack(callBackId: number) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/call/do-callback`),
            {callBackId}, {headers: { ignoreLoadingBar: '' }});
    }

    chatDecline(chatId) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/chat/${chatId}/decline`),
            {}, {headers: { ignoreLoadingBar: '' }});
    }

    chatInformer(chatId) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/chat/${chatId}/informer`), {
            chatId: chatId,
        }, {headers: { ignoreLoadingBar: '' }});
    }

    chatAccept(chatId) {
        return this.httpClient.post(this.backRouter.makeBackUrl(`/rest/reading/${chatId}/chat/accept`), {
            chatId: chatId,
        }, {headers: { ignoreLoadingBar: '' }});
    }
}
