import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NotificationModalComponent} from '../notification-modal/notification-modal.component';
import {UtilService} from '../../../../../services/util.service';

@Component({
    selector: 'app-audio-modal',
    templateUrl: './audio-modal.component.html',
    styleUrls: ['./audio-modal.component.scss'],
})
export class AudioModalComponent implements OnInit {

    constructor(
        public bsModalRef: BsModalRef,
        private bsModalService: BsModalService,
        private utilService: UtilService,
    ) {
    }

    ngOnInit() {
    }

    async play() {
        const self = this;
        const audio = new Audio();
        audio.src = 'assets/flashbeep_alert.mp3';
        audio.load();
        await audio.play();
        // Save information to do not show this modal again unleast they logout
        self.utilService.localStorageSetItem('testSound', 'true');
        this.bsModalRef.hide();
        if (!this.checkNotifications()) {
            this.bsModalService.show(NotificationModalComponent, {
                class: 'modal-sm',
                backdrop: 'static',
                keyboard: false,
                ignoreBackdropClick: true,
                animated: false,
            });
        } else {
            // location.reload();
        }
    }

    checkNotifications() {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (!isMobile) {
            if (!window.Notification) {
                return false;
            } else {
                // check if permission is already granted
                if (Notification.permission === 'granted') {
                    return true;
                } else {
                    // request permission from user
                    Notification.requestPermission().then(function (p) {
                        if (p === 'granted') {
                            return true;
                        } else {
                            return false;
                        }
                    }).catch(function (e) {
                        console.error(e);
                        return false;
                    });
                }
            }
        } else {
            return true;
        }

    }

}
